export const removeDupsById = objects => {
  let unique = {}
  objects.forEach(obj => {
    if (!unique[obj.id]) {
      unique[obj.id] = obj
    }
  })
  return Object.values(unique)
}

export const formatDate = dateString => {
  return dateString.split("T")[0]
}

export const convertShopifyAdminId = (id, type) => {
  return `gid://shopify/${type}/${id}`
}

export const reverseShopifyAdminId = id => {
  return parseInt(id.split("/").pop())
}

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
