import React, { useState, useCallback, useEffect } from "react"
import { Layout, TextField, Card, FormLayout } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

const BraintreeSettings = props => {
  const { firebase, shop } = props

  const [merchantAccountId, setMerchantAccountId] = useState(null)
  const [planSuffix, setPlanSuffix] = useState(null)
  const [updating, setUpdating] = useState(false)

  const handleMerchantAccountId = useCallback(value => {
    setMerchantAccountId(value)
  }, [])
  const handlePlanSuffix = useCallback(value => {
    setPlanSuffix(value)
  }, [])

  const handleSaveChange = () => {
    setUpdating(true)
    firebase
      .firestore()
      .doc(`shops/${shop}/settings/braintree`)
      .set(
        {
          merchantAccountId,
          planSuffix,
        },
        { merge: true }
      )
      .then(() => {
        setUpdating(false)
      })
  }

  useEffect(() => {
    const getGlobalSettings = firebase
      .firestore()
      .doc(`shops/${shop}/settings/braintree`)
      .onSnapshot(snapshot => {
        const data = snapshot.data()
        if (data) {
          setMerchantAccountId(data.merchantAccountId || null)
          setPlanSuffix(data.planSuffix || null)
        }
      })

    return () => getGlobalSettings
  }, [firebase, shop])

  return (
    <>
      <Layout.AnnotatedSection title="Braintree Settings" description="Choose a merchant account id.">
        <Card
          sectioned
          primaryFooterAction={{
            content: "Save",
            onAction: handleSaveChange,
            loading: updating,
          }}
        >
          <FormLayout>
            <TextField type="text" label="Merchant account id" value={merchantAccountId} onChange={handleMerchantAccountId} autoComplete="off" />
            <TextField type="text" label="Subscription plan suffix" value={planSuffix} onChange={handlePlanSuffix} autoComplete="off" />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </>
  )
}

export default ReactifyApp.Firebase.withFirebase(BraintreeSettings)
