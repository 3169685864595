import React from "react"
import { Layout } from "@shopify/polaris"

import AdminSettings from "./AdminSettings"
import GlobalCriteria from "./GlobalCriteria"
import BraintreeSettings from "./BraintreeSettings"

const Settings = props => {
  return (
    <Layout>
      <AdminSettings {...props} />
      <GlobalCriteria {...props} />
      <BraintreeSettings {...props} />
    </Layout>
  )
}

export default Settings
