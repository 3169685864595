import React, { useState, useCallback, useEffect } from "react"
import { Layout, ChoiceList, TextField, Card, FormLayout, Stack, TextStyle, SettingToggle, TextContainer, Tag } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"
import { ResourcePicker } from "@shopify/app-bridge-react"

import { removeDupsById } from "../../helpers/utils"

const GlobalCriteria = props => {
  const { firebase, shop } = props

  const [active, setActive] = useState(null)
  const [rushFeeActive, setRushFeeActive] = useState(null)
  const [minimumPrice, setMinimumPrice] = useState(null)
  const [collection, setCollection] = useState([])
  const [availableTerms, setAvailableTerms] = useState([])
  const [rawData, setRawData] = useState(null)
  const [changes, setChanges] = useState(null)
  const [modalActive, setModalActive] = useState(false)

  const contentStatus = active ? "Disable" : "Enable"
  const textStatus = active ? "enabled" : "disabled"
  const rushFeeStatus = rushFeeActive ? "enabled" : "disabled"
  const rushFeeContentStatus = rushFeeActive ? "Disable" : "Enable"

  const handleToggleActive = useCallback(() => setActive(active => !active), [])
  const handleToggleRushFeeActive = useCallback(() => setRushFeeActive(rushFeeActive => !rushFeeActive), [])
  const handleOpenModal = useCallback(() => setModalActive(true), [])
  const handleCloseModal = useCallback(() => setModalActive(false), [])
  const handleAvilableTermChange = useCallback(value => {
    setAvailableTerms(value)
  }, [])
  const handleMinimumPriceChange = useCallback(value => {
    setMinimumPrice(value)
  }, [])
  const handleAddCollection = selectPayload => {
    setCollection(
      removeDupsById(collection.concat(selectPayload.selection)).map(collection => ({
        description: collection.description,
        handle: collection.handle,
        id: collection.id,
        title: collection.title,
      }))
    )
    setModalActive(false)
  }
  const handleRemoveCollection = item => {
    setCollection(collection.filter(i => i.id !== item.id))
  }

  const handleReset = () => {
    setMinimumPrice(rawData.minimumPrice || null)
    setAvailableTerms(rawData.availableTerms || [])
    setCollection(rawData.collection || [])
  }

  const handleSaveChange = () => {
    firebase
      .firestore()
      .doc(`shops/${shop}/settings/global`)
      .set({ minimumPrice, availableTerms, collection }, { merge: true })
      .then(() => {
        setChanges(null)
      })
  }

  useEffect(() => {
    if (active !== null) {
      const handleSaveActive = () => {
        firebase
          .firestore()
          .doc(`shops/${shop}/settings/global`)
          .set({ active }, { merge: true })
      }
      handleSaveActive()
    }
  }, [active, firebase, shop])

  useEffect(() => {
    if (rushFeeActive !== null) {
      const handleSaveRushFeeActive = () => {
        firebase
          .firestore()
          .doc(`shops/${shop}/settings/global`)
          .set({ rushFeeActive }, { merge: true })
      }
      handleSaveRushFeeActive()
    }
  }, [rushFeeActive, firebase, shop])

  useEffect(() => {
    const getGlobalSettings = firebase
      .firestore()
      .doc(`shops/${shop}/settings/global`)
      .onSnapshot(snapshot => {
        const data = snapshot.data()
        if (data) {
          setRawData(data)
          setActive(data.active || false)
          setMinimumPrice(data.minimumPrice || null)
          setAvailableTerms(data.availableTerms || [])
          setCollection(data.collection || [])
        }
      })

    return () => getGlobalSettings
  }, [firebase, shop])

  useEffect(() => {
    const checkFormState = () => {
      let output = {}
      if (minimumPrice !== rawData?.minimumPrice) output.minimumPrice = minimumPrice
      if (collection !== rawData?.collection) output.collection = collection
      if (availableTerms !== rawData?.availableTerms) output.availableTerms = availableTerms

      return Object.keys(output).length > 0 ? output : null
    }
    setChanges(checkFormState())
  }, [minimumPrice, collection, availableTerms, rawData])

  const termOptions = [
    { label: "6 months", value: "payment-plan:6" },
    { label: "4 months", value: "payment-plan:4" },
    { label: "2 months", value: "payment-plan:2" },
  ]

  const buildCollectionActions = () => {
    const actions = []
    if (collection.length) {
      actions.push({ content: "Clear All", destructive: true, onAction: () => setCollection([]) })
    }

    actions.push({ content: "Select Collections", onAction: handleOpenModal })

    return actions
  }

  return (
    <>
      <Layout.AnnotatedSection
        title="Global criteria"
        description="Set global eligibility criteria that makes all products matching the specified criteria available for payment plans."
      >
        <SettingToggle
          action={{
            content: contentStatus,
            onAction: handleToggleActive,
          }}
          enabled={active}
        >
          {`The global eligibility criteria is `}
          <TextStyle variation="strong">{textStatus}</TextStyle>.
        </SettingToggle>

        <SettingToggle
          action={{
            content: rushFeeContentStatus,
            onAction: handleToggleRushFeeActive,
          }}
          enabled={rushFeeActive}
        >
          {`The rush fee is `}
          <TextStyle variation="strong">{rushFeeStatus}</TextStyle>.
        </SettingToggle>

        <Card
          secondaryFooterActions={[
            {
              content: "Reset",
              onAction: handleReset,
              disabled: !changes,
            },
          ]}
          primaryFooterAction={{
            content: "Save",
            onAction: handleSaveChange,
            disabled: !changes,
          }}
        >
          <Card.Section title="Set Minimum Price">
            <FormLayout>
              <TextField type="number" value={minimumPrice || 0} onChange={handleMinimumPriceChange} autoComplete="off" />
            </FormLayout>
          </Card.Section>
          <Card.Section title="Set Collection" actions={buildCollectionActions()}>
            {collection.length ? (
              <Stack>
                {collection.map(item => (
                  <Tag key={item.id} onClick={() => handleRemoveCollection(item)}>
                    {item.title}
                  </Tag>
                ))}
              </Stack>
            ) : (
              <TextContainer>
                <TextStyle variation="subdued">{`No collection selected`}</TextStyle>
              </TextContainer>
            )}
          </Card.Section>
          <Card.Section title="Set Available Terms">
            <ChoiceList allowMultiple choices={termOptions} selected={availableTerms} onChange={handleAvilableTermChange} />
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>

      <ResourcePicker resourceType="Collection" open={modalActive} onCancel={handleCloseModal} onSelection={handleAddCollection} allowMultiple />
    </>
  )
}

export default ReactifyApp.Firebase.withFirebase(GlobalCriteria)
