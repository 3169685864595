import { navigate } from "gatsby"

export const genericNavBar = {
  primaryAction: {
    content: "Settings",
    onAction: () => navigate("/settings"),
  },
  secondaryActions: [
    { content: "Products", onAction: () => navigate("/products") },
    { content: "Plans", onAction: () => navigate("/payment/list") },
    { content: "Create New Plan", onAction: () => navigate("/order") },
    { content: "Logs", onAction: () => navigate("/logs") },
  ],
}
