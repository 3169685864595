import React from "react"
import { RoutePropagator, TitleBar } from "@shopify/app-bridge-react"
import { Page } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

import { genericNavBar } from "../helpers/navigation"

import Settings from "components/Settings/Settings"

const SettingsPage = props => {
  const { location } = props
  const config = {
    ...genericNavBar,
    title: "Settings",
  }

  return (
    <Page title={config.title}>
      {location && <RoutePropagator location={location} />}
      <TitleBar {...config} />
      <Settings />
    </Page>
  )
}

export default ReactifyApp.Firebase.withFirebase(SettingsPage)
